import React from 'react';
import { Table, Button } from 'react-bootstrap';
import './ScrollableTablePreds.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { gerarPdf } from '../../utils/PDFGenerators';
import { formatDateToLocale } from '../Formatters/dateFormatters';


const ScrollableTablePreds = ({ predData, matchesData, stadiums, soccerTeams, onItemClick, userEmail }) => {

  return (
    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Campeonato</th>
            <th>Temporada</th>
            <th>Jogo</th>
            <th>Data</th>
            <th>Estádio</th>
            <th>Mandante</th>
            <th>Visitante</th>
            <th>Horário</th>
            <th>Preço Médio</th>
            <th>Público</th>
            <th>Gerado</th>
            <th>Ações</th> {/* New column for the button */}
          </tr>
        </thead>
        <tbody>
          {predData.map((item, index) => {
            const predDataFormatted = formatDateToLocale(new Date(item.Data));
            const predDataPrevisaoFormatted = new Date(item.DataPrevisao).toLocaleDateString();
            const horaPrevisaoFormatted = new Date(item.DataPrevisao).toLocaleTimeString();

            return (
              <tr key={index} onClick={() => onItemClick(item)}>
                <td>{index + 1}</td>
                <td>{item.Campeonato}</td>
                <td>{item.Temporada}</td>
                <td>{item.Jogo}</td>
                <td>{predDataFormatted}</td>
                <td>{item.Estadio}</td>
                <td>{item.Mandante}</td>
                <td>{item.Visitante}</td>
                <td>{item.Horario}</td>
                <td>R$ {item.PrecoMedio}</td>
                <td>{item.Publico}</td>
                <td>{predDataPrevisaoFormatted} {horaPrevisaoFormatted}</td>
                <td>
                  <Button className='btn btn-custom' onClick={() => gerarPdf(item, matchesData, stadiums, soccerTeams, userEmail)}>PDF</Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ScrollableTablePreds;
