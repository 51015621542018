import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const gerarPdf = (item, matchesData, stadiums, soccerTeams, userEmail) => {
  // Create a new jsPDF instance
  console.log(item);
  const doc = new jsPDF();

  // Convert the image to Base64
  const imgData = require('../img/appiaLogoBlack.png'); // Using require to obtain Base64 data

  // Add image to the PDF
  const imageWidth = 50;
  const pageWidth = doc.internal.pageSize.getWidth();
  const xCoordinate = pageWidth - imageWidth - 5;
  doc.addImage(imgData, 'PNG', xCoordinate, 10, 30, 30);

  // Set font style to bold and size to 20
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(20);

  // Add title "Relatório da Previsão" on the left corner, aligned left
  doc.text('Appia Sports : Relatório da Previsão', 10, 20);

  // Add another title "Últimas Partidas"
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(15);
  doc.text('Dados da Previsão', 10, 40);

  // Reset font style and size for other text
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(12);

  // Add a table with item details in vertical layout
  const tableData = [
      ['N.º da Previsão', item.SoccerPredictionID],
      ['Usuário', userEmail],
      ['Campeonato', item.Campeonato],
      ['Temporada', item.Temporada],
      ['Jogo', item.Jogo],
      ['Data', formatDate(new Date(item.Data))],
      ['Estádio', item.Estadio],
      ['Mandante', item.Mandante],
      ['Visitante', item.Visitante],
      ['Horário', item.Horario],
      ['Preço Médio', `R$ ${item.PrecoMedio},00`],
      ['Público', formatNumberWithCommas(item.Publico)],
      ['Data e hora da previsão', formatDateTime(new Date(item.DataPrevisao))]
  ];

  // Set styles for the table
  doc.setFillColor(255, 255, 255); // White color for the background

  // Add the table with adjusted settings
  doc.autoTable({
      body: tableData,
      startY: 50, // Adjust the vertical position of the table
      styles: { fontSize: 12, cellPadding: 2 },
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Set styles for headers
      columnStyles: { 0: { fontStyle: 'bold' } } // Set font style to bold for the first column
  });

  // Add a new line after the first table
  doc.text('', 10, doc.autoTable.previous.finalY + 10);

  // Display filtered data in another autotable
  const filteredData = lastMatches(item, matchesData, 3); // Call your function to filter data

  // Set font style to bold and size to 20
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(15);

  doc.text('Últimas Partidas', 10, doc.autoTable.previous.finalY + 20); // Title for filtered data
  doc.autoTable({
      head: [['Mandante', 'Visitante', 'Estádio', 'Data', 'Horário', 'Público']],
      body: filteredData.map(item => [item.Mandante, item.Visitante, item.Estadio, formatDate(new Date(item.Data)), item.Horario, formatNumberWithCommas(item.Publico)]),
      startY: doc.autoTable.previous.finalY + 30, // Adjust the vertical position
      styles: { fontSize: 12, cellPadding: 2 },
      headStyles: { fillColor: [50, 50, 50], textColor: [255, 255, 255] } // Set styles for headers
  });

  // Save the PDF and offer it for download
  const filename = `AppiaSports-${item.SoccerPredictionID}.pdf`;
  doc.save(filename);
};


  // Function to format date to "dd/mm/yyyy"
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to format date and time to "dd/mm/yyyy hh:mm:ss"
const formatDateTime = (dateTime) => {
  const date = dateTime.getDate().toString().padStart(2, '0');
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const year = dateTime.getFullYear();
  const hours = dateTime.getHours().toString().padStart(2, '0');
  const minutes = dateTime.getMinutes().toString().padStart(2, '0');
  const seconds = dateTime.getSeconds().toString().padStart(2, '0');
  return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};



const lastMatches = (item, matches, slice) => {
  const campeonato = item.Campeonato;
  const temporada = item.Temporada;
  const mandante = item.Mandante;

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours to 0 to compare dates accurately

  const filteredData = matches.filter(item => (
      item.Campeonato === campeonato &&
      item.Temporada === temporada &&
      item.Mandante === mandante &&
      new Date(item.Data) < today // Filter matches before today
  ));

  // Sort the filtered data by date in ascending order (most recent first)
  filteredData.sort((a, b) => new Date(b.Data) - new Date(a.Data));
  // Return the last `slice` items
  return filteredData.slice(0, slice);
};

const formatNumberWithCommas = (number) => {
  // Convert number to string
  const num = parseInt(number);
  const numStr = num.toString();
  //console.log(typeof numStr);
  
  // Insert commas every three digits from the right
  let formattedNumber = '';
  let count = 0;
  for (let i = numStr.length - 1; i >= 0; i--) {
      formattedNumber = numStr[i] + formattedNumber;
      count++;
      if (count % 3 === 0 && i !== 0) {
          formattedNumber = '.' + formattedNumber;
      }
  }
  
  return formattedNumber;
};


