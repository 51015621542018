export function getFieldsWithNullOrEmptyValues(obj) {
    const fieldsWithNullEmpty = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === '') {
          const field_formatted = key.replace('selected', '').charAt(0).toUpperCase() + key.replace('selected', '').slice(1);
          fieldsWithNullEmpty.push(field_formatted);
        }
      }
    }
    return fieldsWithNullEmpty;
  }