export function formatDate(date) {
    // Get day, month, and year from the Date object
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
    let year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }

    // Return the formatted date string in DD-MM-YYYY format
    return year + '-' + month + '-' + day;
}

export function formatTimeHM(date) {
    // Get hours and minutes from the Date object
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Pad hours and minutes with leading zeros if necessary
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    // Return the formatted time string in HH:MM format
    return hours + ':' + minutes;
}

export function parseTimeString(timeString){
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  };

  export function formatDateToLocale(date) {
    // Get day, month, and year from the Date object
    let day = date.getDate() + 1;
    let month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
    let year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }

    // Return the formatted date string in DD-MM-YYYY format
    return day + '/' + month + '/' + year;
}