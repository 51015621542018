
  export const fetchJson = async (url, retries) => {

    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true"}
    });
    if (res.ok) {
      return res.json()
    }
    else if (retries > 0) {
  
      return setTimeout(() => {fetchJson(url, retries - 1)}, 3000);
    } else{
    throw new Error(res.status)
    }
  };

// fetch-helpers.js
  // get JSON from multiple URLs and pass to setters
export const fetchAll = (stocks, url) => {

    const requests = stocks.map((stock) => {
      const new_url = url.replace("{stock}", stock)
      return fetchJson(new_url, 1)
       .then((a) => {
        return a 
        })
    })
    return Promise.all(requests) // Aguardando até que todas as solicitações sejam resolvidas.
  };

  export const fetchJsonPost = async (url, data) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set the appropriate content type
        // Add any other headers as needed
      },
      body: JSON.stringify(data) // Convert data to JSON format
    };
  
    try {
      const response = await fetch(url, options);
      const responseStatus = await response.status;
      const responseText = await response.text(); // Retrieve the response text
      return [responseStatus, responseText];
    } catch (error) {
      console.error('Error in fetching ' + url + ' : ', error);
      // Handle errors
      throw error; // Rethrow the error if needed
    }
  };
  