import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Card, ListGroup, Row, Col,Container, Spinner, Badge} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import MoneyInput from '../Formatters/moneyInput';
import MaxAudienceInput from '../Formatters/MaxAudienceImput';
import {fetchJson, fetchJsonPost } from '../../utils/fetchHelpers';
import {formatDate, formatTimeHM, parseTimeString, formatDateToLocale} from '../Formatters/dateFormatters';
import {getFieldsWithNullOrEmptyValues} from '../../utils/DataHelpers';
import ScrollableTable from '../ScrollableTablePreds/ScrollableTablePreds';
import './CrudTable.css';

const CrudTable = (props) => {
  // props 
  const soccerMatches = props.soccerMatches;
  const stadiums  = props.stadiums;
  const soccerTeams  = props.soccerTeams;
  const competitions = props.competitions;
  const userID = props.userID;
  const userEmail = props.userEmail; 

  //selected data in forms to submit to API
  const [selectedCompetition, setSelectedCompetition] = useState('');
  const [selectedSoccerTeams, setSelectedSoccerTeams] = useState('');
  const [selectedsoccerMatches, setSelectedSoccerMatches] = useState('');

  const [formData, setFormData] = useState({

    selectedDate: null,
    selectedStadium: '',
    selectedTime: null,
    selectedVisitante: '',
    selectedMandante: '',
    selectedPrice: 50,
    selectedJogo: '',
    //selectedRodada: '',
    selectedFase: '',
    selectedPublicoMax: ''
  });
  

  // Function to set one specific field in formData
  const setField = (fieldName, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [fieldName]: value
    }));
  };

  const [userPredictions, setUserPredictions] = useState(0);
  const [predictions, setPredictions] = useState([]);
  const [lastAudience, setLastAudience] = useState('');
  const [lastMandante, setLastMandante] = useState('');
  const [lastVisitante, setLastVisitante] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [lastTime, setLastTime] = useState('');
  const [lastEstadio, setLastEstadio] = useState('');
  const [lastPrecoMedio, setLastPrecoMedio] = useState('');

  const [loadingPreds, setloadingPreds] = useState(false);
  const [generatedPreds, setGeneratedPreds] = useState(false);
  const [loadingPredsTable, setloadingPredsTable] = useState(false);
  const [statusCode, setStatusCode] = useState('');
  const [currentPred, setCurrentPred] = useState('');
  const [errorInPredict, setErrorInPredict] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  
  

  // Filtered subcategories based on selected category
  const filteredSoccerMatches = soccerMatches.filter(
    soccerMatch => soccerMatch.Campeonato === selectedCompetition && 
    new Date(soccerMatch.Data) > new Date() &&
    (soccerMatch.Mandante === selectedSoccerTeams || soccerMatch.Visitante === selectedSoccerTeams));
  
  

  const modelSoccerMatch = [{Ano: 2024, Campeonato: selectedCompetition,
   ColocacaoMandante: 2, ColocacaoVisitante: 3, CompetitionID: 20, Data: "2024-03-17T00:00:00.000Z", Estadio: "Independência",
   FaseCampeonato: "Semifinal", GolsMandante: null, GolsVisitante: null, Horario: "19:00", IngressosDevolvidos: null, IngressosGratis: null, 
   IngressosVendidos: null, Jogo: 58, JogoEmpatado: null, Mandante: "America - MG", MandanteID: 1, PrecoMedio: 50, PublicoMax: 23018, Rodada: 2,
   StadiumID: 43, Temporada: "2024", Visitante: "Atletico - MG",  VisitanteID: 4}]
  
   const modelSoccerMatch2 = [{Campeonato: selectedCompetition,
    ColocacaoMandante: 2, ColocacaoVisitante: 3, CompetitionID: selectedCompetition, Data: new Date(), Estadio: "",
    FaseCampeonato: "", GolsMandante: null, GolsVisitante: null, Horario: "19:00", IngressosDevolvidos: null, IngressosGratis: null, 
    IngressosVendidos: null, Jogo: 58, JogoEmpatado: null, Mandante: "", MandanteID: '', PrecoMedio: 50, PublicoMax: 0, Rodada: 1,
    StadiumID: 43, Temporada: "2024", Visitante: "",  VisitanteID: ""}]

  

  const fases = ['Pontos Corridos', 'Classificatoria', 'Semifinal', 'Final', 'Repescagem', 'Quartas de Final', 'Oitavas de Final', 'Fase Grupos']

  //deal with modal
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => {setShowModal(true);}
  const handleClose = () => {
    setSelectedCompetition('');
    setSelectedSoccerTeams('');
    setSelectedSoccerMatches('');
    setGeneratedPreds(false);
    setStatusCode('');
    setCurrentPred('');
    setField('selectedPrice', 50);
    setShowModal(false);}

    const fetchPredictions = async () => {
      try{
          setloadingPredsTable(true);
          const predictionsURL ="https://appiasportfdev.appia.com.br/api/GetSoccerPredictions/"+ userID + "/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
          const predictions = await fetchJson(predictionsURL, 1);
          const updatedPredictions = predictions.map(item => {
            const campeonatoObj = competitions.find(entry => entry.CompetitionID === item.CompetitionID);
            const estadioObj = stadiums.find(entry => entry.StadiumID === item.StadiumID);
            const mandanteObj = soccerTeams.find(entry => entry.SoccerTeamID === item.MandanteID);
            const visitanteObj = soccerTeams.find(entry => entry.SoccerTeamID === item.VisitanteID);

            const campeonato = campeonatoObj ? campeonatoObj.Campeonato : 'Unknown Campeonato';
            const estadio = estadioObj ? estadioObj.Estadio : 'Unknown Estadio';
            const mandante = mandanteObj ? mandanteObj.Nome : 'Unknown Mandante';
            const visitante = visitanteObj ? visitanteObj.Nome : 'Unknown Visitante';

            return {
              ...item,
              Campeonato: campeonato,
              Estadio: estadio,
              Mandante: mandante,
              Visitante: visitante
            };
          });
          
          updatedPredictions.sort((a, b) => new Date(b.DataPrevisao) - new Date(a.DataPrevisao));
          setPredictions(updatedPredictions);
          setloadingPredsTable(false);
          
        }catch (e) {
        console.log(e);
        }
      }
      useEffect(() => {
        fetchPredictions();    
      },[])
      
    const fetchPredNumber = async () => {
        try{
            const userPredictionsURL ="https://appiasportfdev.appia.com.br/api/GetUserPredictions/"+ userID + "/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
            const userPredictions = await fetchJson(userPredictionsURL, 1);
            setUserPredictions(userPredictions[0].result);
            
          }catch (e) {
          console.log(e);
          }
        }



  // get usersPredictions whenever the modal is opened
  useEffect(() => {
    fetchPredNumber();


}, [showModal])
// get usersPredictions whenever the modal is opened
useEffect(() => {
  if (filteredSoccerMatches.length >0){
    console.log(filteredSoccerMatches[1].Data);
    console.log(new Date(filteredSoccerMatches[1].Data));
    console.log(filteredSoccerMatches[2].Data);
    console.log(new Date(filteredSoccerMatches[2].Data).toISOString());
    const x = new Date(filteredSoccerMatches[2].Data).toISOString();
    console.log(x.toLocaleDateString);
  }

}, [filteredSoccerMatches])

  //encapsulate data to send to API
  const encapsulateData = (fsm, ssm, uID, std, steam, comp, formData) => {
    //create empty object
    let fData = {};
    if(ssm ==='end'){
      ssm = 0
    }
    fData.Jogo = fsm[ssm].Jogo;
    //fData.Rodada = fsm[ssm].Rodada;
    fData.Temporada = fsm[ssm].Temporada;
    fData.FaseCampeonato = fsm[ssm].FaseCampeonato;
    fData.ColocacaoMandante = fsm[ssm].ColocacaoMandante;
    fData.ColocacaoVisitante = fsm[ssm].ColocacaoVisitante;

    let dayNames = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    fData.DiaSemana = dayNames[formData.selectedDate.getDay()];
    fData.Data = formatDate(formData.selectedDate);
    fData.Horario = formatTimeHM(formData.selectedTime);
    fData.Visitante = formData.selectedVisitante;
    fData.PrecoMedio = parseInt(formData.selectedPrice);
    fData.PublicoMax = formData.selectedPublicoMax;
    fData.UserID = uID;

    fData.StadiumID = std.filter(stadium => stadium.Estadio ===  formData.selectedStadium)[0].StadiumID;
    fData.VisitanteID = steam.filter(team => team.Nome === formData.selectedVisitante)[0].SoccerTeamID;
    fData.MandanteID = steam.filter(team => team.Nome === formData.selectedMandante)[0].SoccerTeamID;
    fData.CompetitionID = comp.filter(competition => competition.Campeonato === selectedCompetition)[0].CompetitionID;

    return fData
  };
  
   


  // when adding a prediction, send encapsulated data to api
  const handleAdd = async () => {

    if(selectedsoccerMatches !== ''){
      if (userPredictions > 0){
        setloadingPreds(true);
        const emptyFields = getFieldsWithNullOrEmptyValues(formData);
        
        if (emptyFields.length === 0){
          let encData = [];
          if(selectedsoccerMatches === 'end'){
          encData = await encapsulateData(modelSoccerMatch, 0, userID, stadiums, soccerTeams, competitions, formData);
          }else{
          encData = await encapsulateData(filteredSoccerMatches, selectedsoccerMatches, userID, stadiums, soccerTeams, competitions, formData);
        }
        const predictAudienceURL ="https://appiasportfdev.appia.com.br/api/PredictAudience/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
        const response = await fetchJsonPost(predictAudienceURL, encData);
        setloadingPreds(false);
        setGeneratedPreds(true);
        setStatusCode(response[0]);
        if(response[0] === 201){
          const predObject = response[1].replace(/'/g, '"');
          const objectParsed = JSON.parse(predObject);
          setCurrentPred(objectParsed);
          fetchPredNumber();
          fetchPredictions();
        }else{
          setCurrentPred(response[1]);
        }
        }else{
          setloadingPreds(false);
          setErrorInPredict(true);
          const message = `Dados incorretos ou faltantes: ${emptyFields.join(', ')}`;
          setAlertMessage(message);
          alert(message);
        }
      }
    }else{
      setErrorInPredict(true);
      setAlertMessage("Previsões esgotadas!");
    }
  };
  function removeTrailingWhiteSpace(str) {
    if (str.charAt(str.length - 1) === ' ') {
        return str.slice(0, -1);
    } else {
        return str;
    }
}
  // updated fields when selectedsoccerMatches changes
  useEffect(() => {

    let soccerMatch = [];
    if (selectedsoccerMatches !== 'end') {
      soccerMatch = filteredSoccerMatches[selectedsoccerMatches];}
    else{
      if (filteredSoccerMatches.length === 0) {
        soccerMatch = modelSoccerMatch[0];
      } else {
        const filteredlastMatch = soccerMatches.filter(soccerMatch =>
          soccerMatch.Campeonato === selectedCompetition &&
          new Date(soccerMatch.Data) <= new Date() &&
          (soccerMatch.Mandante === selectedSoccerTeams));

        const lastMatch = filteredlastMatch.reduce((prev, current) => {
            return (new Date(current.Data) > new Date(prev.Data)) ? current : prev;
        });
        soccerMatch = lastMatch;
      }
    }
        
      if (soccerMatch) {

        const newDate = formatDateToLocale(new Date(soccerMatch.Data));
        const [day, month, year] = newDate.split('/').map(Number);
        const date = new Date(year, month - 1, day);
  
        setField('selectedDate', date);
        setField('selectedStadium', soccerMatch.Estadio);
        setField('selectedFase', removeTrailingWhiteSpace(soccerMatch.FaseCampeonato.toString()));
        //setField('selectedRodada', soccerMatch.Rodada);
        setField('selectedJogo', soccerMatch.Jogo);
        const newTime = parseTimeString(soccerMatch.Horario);
        setField('selectedTime', newTime);
        setField('selectedMandante', soccerMatch.Mandante);
        setField('selectedVisitante', soccerMatch.Visitante);
        const publicoMax = stadiums.filter(entry => entry.Estadio === soccerMatch.Estadio)[0].Capacidade;
        setField('selectedPublicoMax', publicoMax);
        
        
        const today = new Date();
        const stadiumEntries = soccerMatches.filter(entry => entry.StadiumID === soccerMatch.StadiumID && entry.Mandante === soccerMatch.Mandante && new Date(entry.Data) <= today);
        
        // Sort stadium entries by date in descending order
        stadiumEntries.sort((a, b) => new Date(b.Data) - new Date(a.Data));
        
        // Get the most recent entry
        if (stadiumEntries.length === 0) {
          console.log("The list is empty");
        } else {
          setLastAudience(stadiumEntries[0].Publico);
          setLastMandante(stadiumEntries[0].Mandante);
          setLastVisitante(stadiumEntries[0].Visitante);
          setLastDate(new Date(stadiumEntries[0].Data).toLocaleDateString());
          setLastTime(stadiumEntries[0].Horario);
          setLastEstadio(stadiumEntries[0].Estadio);
          setLastPrecoMedio(stadiumEntries[0].PrecoMedio);
        }
    }

  }, [selectedsoccerMatches, selectedCompetition, selectedSoccerTeams]);

  useEffect(() => {
    if(selectedsoccerMatches !== '' && formData.selectedStadium !== ''){
      const publicoMax = stadiums.filter(entry => entry.Estadio === formData.selectedStadium)[0].Capacidade;
    setField('selectedPublicoMax', publicoMax);
    }
  }, [formData.selectedStadium]);

  const handleItemClick = (item) => {
    //console.log('Clicked item:', item);
    // Do something with the clicked item, e.g., navigate to a detail page
  };
  const handleGeneratePDF = () => {
    console.log('Generate PDF');
    // Do something with the clicked item, e.g., navigate to a detail page
  };

  return (
    <div>
        <Button className='btn btn-custom' onClick={handleShow}>Nova Previsão</Button>
      {loadingPredsTable === false? (
        <ScrollableTable predData={predictions} matchesData = {soccerMatches} stadiums = {stadiums} soccerTeams = {soccerTeams} onItemClick={handleItemClick} userEmail = {userEmail} />
      ): <div className="d-flex flex-column align-items-center">
        <Spinner animation="grow" className="custom-spinner-grow"/>
        <p className="mt-3">Carregando Previsões...</p>
        </div>}

        <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-width">
          <Modal.Header closeButton>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Modal.Title>Gerar Previsão</Modal.Title>
              <div className="ms-auto">
                <Badge className="round-label">Previsões disponíveis: {userPredictions}</Badge>
              </div>
            </div>
          </Modal.Header>
      {generatedPreds === false?(
        loadingPreds === false ?(
          <Modal.Body>
            {/* -----------------------------Início do Form----------------------------------- */}
            <Form>
              {/* -----------------------------Início dos SELECTS de Competition, SoccerTeam e SoccerMatches----------------------------------- */}
              <Row>
              <Col sm={6} md={6}>
              {/* First Card */}
              <Card style={{marginBottom: '20px'}}> 
              <Card.Header style={{ backgroundColor: 'black', color: 'white', borderColor: 'black' }}>Partidas</Card.Header>
              <ListGroup variant="flush">
              <ListGroup.Item>
              <Form.Group controlId="CompetitionsSelect">
                <Form.Label>Campeonato</Form.Label>
                <Form.Select value={selectedCompetition} onChange={(event) => setSelectedCompetition(event.target.value)}>
                <option value="">Selecione</option>
                {competitions.map((competition) => (
                  <option key={competition.CompetitionID} value={competition.Campeonato}>
                    {competition.Campeonato}
                  </option>
                ))}
                </Form.Select>
              </Form.Group>
              </ListGroup.Item>

              <ListGroup.Item>
              <Form.Group controlId="SoccerTeamSelect">
                <Form.Label>Time</Form.Label>
                <Form.Select value={selectedSoccerTeams} onChange={(event) => setSelectedSoccerTeams(event.target.value)}>
                <option value="">Selecione</option>
                {soccerTeams.map((team) => (
                <option key={team.soccerTeamID} value={team.Nome}>
                  {team.Nome}
                </option>
                ))}
                </Form.Select>
              </Form.Group>
              </ListGroup.Item>    
              <ListGroup.Item>
              <Form.Group controlId="SoccerMatchesSelect">
                <Form.Label>Partidas</Form.Label>
                <Form.Select value={selectedsoccerMatches} onChange={(event) => setSelectedSoccerMatches(event.target.value)}>
                <option value="">Selecione</option>
                {filteredSoccerMatches.map((match, index) => (
                  <option key={index} value={index}>
                    {match.Mandante} X {match.Visitante} - {formatDateToLocale(new Date(match.Data))} - {match.Horario} - {match.Estadio}
                  </option>
                ))}
                <option key="end" value="end">Nova partida</option>
                </Form.Select>
              </Form.Group>
              </ListGroup.Item>
              </ListGroup>
              </Card>
              <Row>
              <Col sm={12} md={12}>
                {/* Second Card */}
              

              {selectedsoccerMatches && selectedCompetition && selectedSoccerTeams ? (
                <Card style={{marginBottom: '7px'}}> 
                  <Card.Header style={{ backgroundColor: 'black', color: 'white', borderColor: 'black' }}>Última Partida</Card.Header>
                    <ListGroup variant="flush">
                      <ListGroup.Item>Público: {lastAudience}</ListGroup.Item>
                      <ListGroup.Item>Estádio: {lastEstadio}</ListGroup.Item>
                      <ListGroup.Item>Mandante: {lastMandante}</ListGroup.Item>
                      <ListGroup.Item>Visitante: {lastVisitante}</ListGroup.Item>
                      <ListGroup.Item>Data: {lastDate} - {lastTime}</ListGroup.Item> 
                      <ListGroup.Item>Preço Médio: {lastPrecoMedio} </ListGroup.Item>
                    </ListGroup>
                </Card>
            ) : null}
            {/* --------------------------------------Fim dos dados detalhes da partida ----------------------------------------------- */}
            {/* ------------------------------------Início do campo de variáveis da partida ------------------------------------------- */}
            </Col>
            </Row>

              {/* -----------------------------Fim dos SELECTS de Competition, SoccerTeam e SoccerMatches----------------------------------- */}

              {/* --------------------------------------Início dos dados detalhes da partida ----------------------------------------------- */}
              </Col>
          <Col sm={6} md={6}>
            <Row>
              <Col sm={12} md={12}>
                {/* Third Card */}
              {selectedsoccerMatches && selectedCompetition && selectedSoccerTeams ? (
                <Card style={{marginBottom: '20px'}}> 
                  <Card.Header style={{ backgroundColor: 'black', color: 'white', borderColor: 'black' }}>Variáveis</Card.Header>
                    <ListGroup variant="flush">

                    {/*<ListGroup.Item>
                        <Form.Group controlId="Rodada">
                          <Form.Label>Rodada</Form.Label>
                            <MaxAudienceInput value={formData.selectedRodada} onChange={(value) => setField('selectedRodada', value)}/>
                          </Form.Group>
                      </ListGroup.Item> */}

                      <ListGroup.Item>
                          <Form.Group controlId="Fase do Campeonato">
                            <Form.Label>Fase do Campeonato</Form.Label>
                            <Form.Select value={formData.selectedFase} onChange={(e) => setField('selectedFase', e.target.value)}>
                            <option value="">Selecione</option>
                            {fases.map((fase, index) => (
                              <option key={index} value={fase}>
                                {fase}
                              </option>
                            ))}
                            </Form.Select>
                        </Form.Group>
                      </ListGroup.Item>

                      <ListGroup.Item>
                          <Form.Group controlId="Estadio">
                            <Form.Label>Estádio</Form.Label>
                            <Form.Select value={formData.selectedStadium}  onChange={(e) => setField('selectedStadium', e.target.value)}>
                            <option value="">Selecione</option>
                            {stadiums.map((stadium) => (
                              <option key={stadium.StadiumID} value={stadium.Estadio}>
                                {stadium.Estadio}
                              </option>
                            ))}
                            </Form.Select>
                        </Form.Group>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Form.Group controlId="PublicoMax">
                          <Form.Label>Público Máximo</Form.Label>
                            <MaxAudienceInput value={formData.selectedPublicoMax} onChange={(value) => setField('selectedPublicoMax', value)}/>
                          </Form.Group>
                      </ListGroup.Item>    

                      <ListGroup.Item>
                        <Form.Group controlId="formDate">
                            <Form.Label>Data</Form.Label>
                              <DatePicker
                                selected={formData.selectedDate}
                                onChange={(date) => setField('selectedDate', date)}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                            </Form.Group>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Form.Group controlId="formTime">
                            <Form.Label>Horário</Form.Label>
                              <DatePicker
                                selected={formData.selectedTime}
                                onChange={(time) => setField('selectedTime', time)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeFormat="HH:mm"
                                dateFormat="h:mm aa"
                                className="form-control"
                                placeholderText="Selecione"
                              />
                        </Form.Group>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Form.Group controlId="Mandante">
                          <Form.Label>Mandante</Form.Label>
                          <Form.Select value={formData.selectedMandante} onChange={(e) => setField('selectedMandante', e.target.value)}>
                          <option value="">Selecione</option>
                          {soccerTeams.map((team) => (
                            <option key={team.soccerTeamID} value={team.Nome}>
                              {team.Nome}
                            </option>
                          ))}
                          </Form.Select>
                        </Form.Group>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Form.Group controlId="Visitante">
                              <Form.Label>Visitante</Form.Label>
                              <Form.Select value={formData.selectedVisitante} onChange={(e) => setField('selectedVisitante', e.target.value)}>
                              <option value="">Selecione</option>
                              {soccerTeams.map((team) => (
                                <option key={team.soccerTeamID} value={team.Nome}>
                                  {team.Nome}
                                </option>
                              ))}
                            </Form.Select>
                        </Form.Group>
                      </ListGroup.Item>
                      
                      <ListGroup.Item>
                        <Form.Group controlId="price">
                          <Form.Label>Preço Médio</Form.Label>
                          <MoneyInput value={formData.selectedPrice} onChange={(value) => setField('selectedPrice', value)}/>
                          </Form.Group>
                      </ListGroup.Item>
                      </ListGroup>
                      </Card>     
            ) : null}
            {/* -------------------------------------- Fim do campo de variáveis da partida ----------------------------------------------- */}
            {/* ------------------------------------Fim do Form  ------------------------------------------- */}
            </Col>
            </Row>
          </Col>
          </Row>
          </Form>
          </Modal.Body>
        ): 
        <div className="d-flex flex-column align-items-center">
        <Spinner animation="grow" className='custom-spinner-grow' />
        <p className="mt-3">Gerando Previsão...</p>
        </div>):
        
        <Modal.Body>
        {/* -------------------------------------- Início dos dados gerados na Previsão ----------------------------------------------- */}
          {statusCode === 201?(
            <Card> 
            <Card.Header style={{ backgroundColor: 'black', color: 'white', borderColor: 'black'}}>Previsão Gerada com Sucesso</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item>Campeonato: {selectedCompetition}</ListGroup.Item>
                <ListGroup.Item>Temporada: {currentPred.Temporada}</ListGroup.Item>
                {/*<ListGroup.Item>Rodada: {formData.selectedRodada}</ListGroup.Item>*/}
                <ListGroup.Item>Jogo: {currentPred.Jogo}</ListGroup.Item>
                <ListGroup.Item>Fase do Campeonato: {formData.selectedFase}</ListGroup.Item>
                <ListGroup.Item>Estádio: {formData.selectedStadium}</ListGroup.Item>
                <ListGroup.Item>Mandante: {formData.selectedMandante}</ListGroup.Item>
                <ListGroup.Item>Visitante: {formData.selectedVisitante}</ListGroup.Item>
                <ListGroup.Item>Data: {formatDate(formData.selectedDate)}</ListGroup.Item>
                <ListGroup.Item>Horário: {currentPred.Horario}</ListGroup.Item>
                <ListGroup.Item>Preço Médio: R$ {formData.selectedPrice}</ListGroup.Item>
                <ListGroup.Item style={{ backgroundColor: 'black', color: 'white' }}>Público Previsto: {currentPred.Publico} </ListGroup.Item>
              </ListGroup>
          </Card>
          ):
          <p className="mt-3"> Status Code {statusCode} - Erro: {currentPred} </p>}
      {/* -------------------------------------- Fim dos dados gerados na Previsão ----------------------------------------------- */}
        </Modal.Body>
        
        }
    {loadingPreds === false ? (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Fechar</Button>
          {generatedPreds === false?(
            <Button className='btn btn-custom' onClick={handleAdd} disabled={selectedsoccerMatches.trim() === ''}>Gerar Previsão</Button> 
          ):null}
        </Modal.Footer>
        ): null}
      </Modal>
    </div>
  );
};

export default CrudTable;