import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Card } from 'react-bootstrap';
import "../styles/Home.css";
import { Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { Nav } from 'react-bootstrap';


export const Home = () => {

  return (
    <>
      <AuthenticatedTemplate>
      <Navigate to='/predictions' replace={true} />
        <Nav.Link className={window.location.pathname === "/predictions" ? "navbarButton active" : "navbarButton"} href="/predictions">
                           Previsões
        </Nav.Link>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
          <div className="bg-style" style={{ minHeight: '100vh', overflow: 'hidden' }}>
            <div className="container-fluid position-relative">
              <Card className="my-card position-absolute bottom-0 start-0 mb-4 ms-4">
                <Card.Body style={{ paddingBottom: '100px' }}>
                  <Card.Text className="titulo-personalizado">Appia Sports</Card.Text>
                  <Card.Text className="texto-personalizado">
                  Previsão inteligente de público simulando diferentes preços de ingressos para uma melhor tomada de decisão
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
      </UnauthenticatedTemplate>
    </>
  );
};
