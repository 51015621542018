import React, { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';

const ScrollableTableMatches = ({ data, onItemClick }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [competitionFilter, setCompetitionFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [seasonFilter, setSeasonFilter] = useState('');
  const [filteredTeams, setFilteredTeams] = useState([]);

  // Get unique values for Campeonato, Mandante, Visitante, and Temporada
  const uniqueCompetitions = [...new Set(data.map(item => item.Campeonato))];
  const uniqueSeasons = [...new Set(data.map(item => item.Temporada))];

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows([...selectedRows, item]);
    } else {
      setSelectedRows(selectedRows.filter(row => row !== item));
    }
  };

  useEffect(() => {
    if (competitionFilter) {
      const teams = data.filter(item => item.Campeonato === competitionFilter)
                        .flatMap(item => [item.Mandante, item.Visitante]);
      setFilteredTeams([...new Set(teams)]);
    } else {
      setFilteredTeams([]);
    }
  }, [competitionFilter, data]);

  const filteredData = data.filter(item => {
    const matchCompetition = !competitionFilter || item.Campeonato.toLowerCase() === competitionFilter.toLowerCase();
    const matchTeam = !teamFilter || item.Mandante.toLowerCase() === teamFilter.toLowerCase() || 
                      item.Visitante.toLowerCase() === teamFilter.toLowerCase();
    const matchSeason = !seasonFilter || item.Temporada.toLowerCase() === seasonFilter.toLowerCase();
    return matchCompetition && matchTeam && matchSeason;
  });

  return (
    <div>
      <div className="filters">
        <select value={competitionFilter} onChange={e => setCompetitionFilter(e.target.value)}>
          <option value="">Todos os campeonatos</option>
          {uniqueCompetitions.map((competition, index) => (
            <option key={index} value={competition}>{competition}</option>
          ))}
        </select>
        <select value={teamFilter} onChange={e => setTeamFilter(e.target.value)}>
          <option value="">Todos os times</option>
          {filteredTeams.map((team, index) => (
            <option key={index} value={team}>{team}</option>
          ))}
        </select>
        <select value={seasonFilter} onChange={e => setSeasonFilter(e.target.value)}>
          <option value="">Todas as temporadas</option>
          {uniqueSeasons.map((season, index) => (
            <option key={index} value={season}>{season}</option>
          ))}
        </select>
      </div>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th></th>
            <th>Campeonato</th>
            <th>Temporada</th>
            <th>Jogo</th>
            <th>Rodada</th>
            <th>Fase</th>
            <th>Mandante</th>
            <th>Visitante</th>
            <th>Data</th>
            <th>Horário</th>
            <th>Ponto Mandante</th>
            <th>Ponto Visitante</th>
            <th>Estádio</th>
            <th>Público</th>
            <th>Colocação Mandante</th>
            <th>Colocação Visitante</th>
            <th>Preço Médio</th>
            <th>Arrecadação</th>
          </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              const dataFormatted = new Date(item.Data).toLocaleDateString();

              return (
                <tr key={index} onClick={() => onItemClick(item)}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedRows.includes(item)}
                      onChange={e => handleCheckboxChange(e, item)}
                    />
                  </td>
                  <td>{item.Campeonato}</td>
                  <td>{item.Temporada}</td>
                  <td>{item.Jogo}</td>
                  <td>{item.Rodada}</td>
                  <td>{item.FaseCampeonato}</td>
                  <td>{item.Mandante}</td>
                  <td>{item.Visitante}</td>
                  <td>{dataFormatted}</td>
                  <td>{item.Horario}</td>
                  <td>{item.PontoMandante}</td>
                  <td>{item.PontoVisitante}</td>
                  <td>{item.Estadio}</td>
                  <td>{item.Publico}</td>
                  <td>{item.ColocacaoMandante}</td>
                  <td>{item.ColocacaoVisitante}</td>
                  <td>R$ {item.PrecoMedio}</td>
                  <td>R$ {item.Arrecadacao}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ScrollableTableMatches;
