import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import CrudTable from '../components/CrudTable/CrudTable';
import {fetchJson } from '../utils/fetchHelpers';
import '../styles/Predictions.css'


export const Predictions = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let currentDate = new Date().toJSON().slice(0, 10);

    //urls to fetch apis
    
    const soccerMatchesURL = "https://appiasportfdev.appia.com.br/api/GetMatch/0/0/0/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
    const stadiumsfURL = "https://appiasportfdev.appia.com.br/api/GetStadiumID/ALL/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
    const soccerTeamsURL = "https://appiasportfdev.appia.com.br/api/GetSoccerTeam/0/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
    const competitionsURL = "https://appiasportfdev.appia.com.br/api/GetCompetitionID/ALL/?code=" + process.env.REACT_APP_APPIA_SPORTS_KEY + "&clientId=appiasportskey"
    
    const [soccerMatches, setsoccerMatches] = useState([]);
    const [stadiums, setStadiums] = useState([]);
    const [soccerTeams, setsoccerTeams] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [userID, setUserID] = useState('');
    const [userEmail, setUserEmail] = useState('');

    //controls
    const [fetchLoaded, setFetchloaded] = useState(false);
    const [errorFetch, setErrorFetch] = useState(false);

    useEffect(() => {


        const fetchData = async () => {
            
            try {
                
                //get from local storage
                //const soccerMatchesLS = JSON.parse(localStorage.getItem('soccerMatchesLS'));
                const stadiumsLS = JSON.parse(localStorage.getItem('stadiumsLS'));
                const soccerTeamsLS = JSON.parse(localStorage.getItem('soccerTeamsLS'))
                const competitionsLS = JSON.parse(localStorage.getItem('competitionLS'))

        

                if (stadiumsLS && soccerTeamsLS && competitionsLS) {
                    // set variables from LS
                    //await setsoccerMatches(soccerMatchesLS);
                    await setStadiums(stadiumsLS);
                    await setsoccerTeams(soccerTeamsLS);
                    await setCompetitions(competitionsLS)
                    await setFetchloaded(true);

                }else{

                // fetch soccer matches
                const soccerMatches = await fetchJson(soccerMatchesURL, 1);
                setsoccerMatches(soccerMatches)
                
                //await localStorage.setItem('soccerMatchesLS', JSON.stringify(soccerMatches));


                //fetch stadiums
                const stadiums = await fetchJson(stadiumsfURL, 1);
                stadiums.sort((a, b) => {return a.Estadio.localeCompare(b.Estadio);});
                setStadiums(stadiums)
                await localStorage.setItem('stadiumsLS', JSON.stringify(stadiums));
               

                // fetch soccer teams
                const soccerTeams = await fetchJson(soccerTeamsURL, 1);
                // Sort soccer teams by the "Nome" field
                soccerTeams.sort((a, b) => {return a.Nome.localeCompare(b.Nome);});
                setsoccerTeams(soccerTeams)
                await localStorage.setItem('soccerTeamsLS', JSON.stringify(soccerTeams));
              

                //fetch competitions
                const competitions = await fetchJson(competitionsURL, 1);
                setCompetitions(competitions);
                await localStorage.setItem('competitionsLS', JSON.stringify(competitions));

                //get user id
                setUserID(activeAccount.localAccountId);
                setUserEmail(activeAccount.username);

                //load page 
                await setFetchloaded(true);
                   }}
            catch (e) {
                console.log(e);
                setErrorFetch(true);
            }

        }
        fetchData();
    }, [])


    return (
        <>
            <AuthenticatedTemplate>
                <Container>
                     {fetchLoaded?
                        <CrudTable competitions={competitions} soccerMatches={soccerMatches} stadiums = {stadiums} soccerTeams = {soccerTeams} userID = {userID} userEmail = {userEmail} />
                            :
                            errorFetch === false ?
                                <Container fluid className="d-flex justify-content-center mh-100">
                                    <Spinner animation="grow" className='custom-spinner-grow'/>
                                </Container>
                                : <a>Erro ao carregar os recursos! </a>}
                </Container>
            </AuthenticatedTemplate>
        </>
    );
};