import React from 'react';
import { Form } from 'react-bootstrap';
import {NumericFormat} from 'react-number-format';

const MaxAudienceInput = ({ value, onChange }) => {
  const handleChange = (values) => {
    if (onChange) {
      onChange(values.value);
    }
  };

  return (
    <NumericFormat
      value={value}
      thousandSeparator={false}
      onValueChange={handleChange}
      allowNegative={false}
      allowLeadingZeros={false}
      isNumericString={true}
      customInput={Form.Control}
    />
  );
};

export default MaxAudienceInput;